import { Link as RouterLink } from 'react-router-dom';

// material-ui
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
export default function Footer() {
  const currentYear = new Date().getFullYear(); // Get current year dynamically
  const theme = useTheme();
  return (
    <Stack
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      sx={{
        p: 0,
        mt: 'auto',
        textAlign: 'center',
        position: 'relative', // Change from fixed/absolute if it was
        bottom: 0,
        // width: '100%',
        backgroundColor: theme.palette.mode === ('dark' as PaletteMode) ? '#121212' : '#fafafb', // Add background color
        zIndex: 1, // Ensure it's above content but below modals
      }}
    >
      <Typography variant='caption' color='text.secondary'>
        &copy; {currentYear} iomarket {" | "}

        {process.env.REACT_APP_TERMS_AND_CONDITIONS_URL && (
          <>
            <Link
              component={RouterLink}
              to={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
              target='_blank'
              variant='caption'
              color='text.secondary'
            >
              <FormattedMessage id='common_all_terms_and_conditions' />
            </Link>
            {" | "}
          </>
        )}
        {process.env.REACT_APP_DATA_PROCESSING_AGREEMENT_URL && (
          <>
            <Link
              component={RouterLink}
              to={process.env.REACT_APP_DATA_PROCESSING_AGREEMENT_URL}
              target='_blank'
              variant='caption'
              color='text.secondary'
            >
              <FormattedMessage id='common_all_data_processing_agreement' />
            </Link>
            {" | "}
          </>
        )}
        {process.env.REACT_APP_PRIVACY_POLICY_URL && (
          <>
            <Link
              component={RouterLink}
              to={process.env.REACT_APP_PRIVACY_POLICY_URL}
              target='_blank'
              variant='caption'
              color='text.secondary'
            >
              <FormattedMessage id='common_all_privacy_policy' />
            </Link>
            {" | "}
          </>
        )}
        {process.env.REACT_APP_LEGAL_NOTICE_URL && (
          <>
            <Link
              component={RouterLink}
              to={process.env.REACT_APP_LEGAL_NOTICE_URL}
              target='_blank'
              variant='caption'
              color='text.secondary'
            >
              <FormattedMessage id='common_all_legal_notices' />
            </Link>
          </>
        )}
      </Typography>
    </Stack>
  );
}
