// project import
import Iom from './themeIom';
import Xatenna from "./themeXatenna";

// types
import { PaletteThemeProps } from 'types/theme';
import { PalettesProps } from '@ant-design/colors';
import { ThemeMode, PresetColor } from 'types/config';

// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = (colors: PalettesProps, presetColor: PresetColor, mode: ThemeMode): PaletteThemeProps => {
  switch (presetColor) {
    case "iom":
      return Iom(colors, mode);
    case "xatenna":
      return Xatenna(colors, mode);
    default:
      return Iom(colors, mode);
  }
};

export default Theme;
